%collapse {
  &.collapse {
    padding: 0;
    max-height: 0;
    display: none;
    visibility: hidden;
  }
}

.ad-abuse-form {
  @extend %collapse;
  .row-container {
    padding-top: var(--sbt-spacing-lg);
    > p {
      margin-bottom: var(--sbt-spacing-2xs);
    }
  }
  .text-area-wrapper {
    textarea {
      padding-top: var(--sbt-spacing-2xs);
    }
  }
  .submit-wrapper {
    width: 100%;
    margin-top: var(--sbt-spacing-md);
    text-align: right;
  }
}

.abuse-success-wrapper {
  margin: 0 auto;
  padding: var(--sbt-spacing-md);
  text-align: center;
  @extend %collapse;
  h5 {
    padding: var(--sbt-spacing-xl);
  }

  svg {
    content: '';
    width: var(--sbt-layout-lg);
    height: var(--sbt-layout-lg);
    margin: 0 auto;
    display: block;
    position: relative;
    background-repeat: no-repeat;
    animation-name: bounceIn;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: both;
  }
}

.abuse-error-wrapper {
  margin: 0 auto;
  padding: var(--sbt-spacing-md);
  text-align: center;
  @extend %collapse;

  &.send-error {
    display: inline-block;
  }
  > div,
  > h5,
  > p {
    margin: var(--sbt-spacing-xl) 0;
  }
}

.icon-check g {
  fill: var(--sbt-accent-green);
}

.privacy-link {
  color: var(--sbt-secondary-text-color);
  text-decoration: underline;
}
