@use '~@sbt-web/ui/common/css/variables';

.increaseVisibilityButton {
  width: 100%;
  justify-content: center;
  display: flex;
  @media (min-width: variables.$tablet_min_width) {
    width: auto;
  }
}
