@use '~@sbt-web/ui/common/css/variables.scss' as v;

.safety-tips-wrapper {
  width: 100%;
  height: var(--sbt-layout-lg);
  border: 1px dashed v.$neutralGrey4;
  border-radius: var(--sbt-corner-lg);
  display: flex;
  align-items: center;
  justify-content: left;

  .safety-tips-icon {
    margin: 0 var(--sbt-spacing-xs) 0 var(--sbt-spacing-md);
  }
}
