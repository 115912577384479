@use '~@sbt-web/ui/common/css/variables.scss' as v;

.outer-ad-container {
  border-bottom: 1px solid v.$neutralGrey4;
  color: var(--sbt-primary-text-color);
}

@media (min-width: v.$desktop_min_width) {
  .outer-ad-container {
    border: 0;
    border-style: solid;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to bottom,
      rgba(223, 227, 237, 0),
      v.$neutralGrey4
    );
  }
}

.inner-ad-container {
  background-color: #fff;
  max-width: var(--sbt-page-width);
  padding-bottom: var(--sbt-spacing-2xl);
  padding-top: var(--sbt-spacing-md);
}

.job-gallery-hidden {
  /*
    Linked to the experiment "job-photo-hidden"
    !important because the grid has a lot of inheritance that isn't worth
    reworking for a test
  */
  display: none !important;
}
