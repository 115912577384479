@use '~@sbt-web/ui/common/css/variables.scss' as v;

.placeholder {
  align-items: center;
  background-color: var(--sbt-background-root);
  display: flex;
  justify-content: center;
}

// From 0 until the tablet max, we have 100% width and 270px height
@media (max-width: v.$tablet_max_width) {
  .placeholder {
    height: 270px;
    width: 100%;
  }
}

// From tablet min width we have rounded corners and no overflow
@media (min-width: v.$tablet_min_width) {
  .placeholder {
    border-radius: var(--sbt-corner-small);
    overflow: hidden;
  }
}

// On desktop we replace the dimensions with a fixed height and width
@media (min-width: v.$desktop_min_width) {
  .placeholder {
    height: 466px;
    width: 620px;
  }
}
