@use '~@sbt-web/ui/common/css/variables' as v;

.wrapper {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  gap: var(--sbt-spacing-2xs);

  @media (min-width: v.$desktop_min_width) {
    /* This value has been given by UX */
    max-width: 260px;
  }
}

.socialization-button {
  background-color: transparent;
  border: none;
  border-radius: var(--sbt-corner-md);
  display: inline-block;
  padding: var(--sbt-spacing-xs);
  text-align: left;
  text-decoration: none;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: var(--sbt-background-root);
    text-decoration: none;
  }
}

.socialization-button-number {
  color: var(--sbt-primary-text-color);

  &:hover {
    color: var(--sbt-primary-text-color);
  }
}

.socialization-button-text {
  color: var(--sbt-secondary-text-color);
}
