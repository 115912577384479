@use '~@sbt-web/ui/common/css/variables';

.confirmMessage {
  padding: 0;
  text-align: center;
  transition:
    max-height ease 0.3s,
    padding ease 0.3s;
  max-height: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sbt-spacing-md);

  &.show {
    max-height: initial;
    padding: var(--sbt-spacing-2xl) 0;
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
    transform: translateX(30px);
  }

  80% {
    opacity: 1;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
