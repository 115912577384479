@use '~@sbt-web/ui/common/css/variables.scss' as v;

.description-title {
  margin-bottom: var(--sbt-spacing-md);
}

.description {
  overflow-wrap: break-word;
}

.copy {
  color: var(--sbt-secondary-text-color);
  margin-top: var(--sbt-spacing-md);
}

.card-block-container {
  margin-top: var(--sbt-spacing-xl);
}
