.infoBox {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-xs);
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-md);
  margin-top: var(--sbt-spacing-md);
  padding: var(--sbt-spacing-sm);
}

.shippingCost,
.proReturns,
.buyerProtection,
.paymentMethods {
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-xs);
  height: var(--sbt-spacing-xl);
};

.returnsCopy,
.shippingCopy {
  display: flex;
  gap: var(--sbt-spacing-2xs);
}

.icons {
  display: flex;
  gap: var(--sbt-spacing-xs);
}