
.btn {
  width: 100%;
}

.marginBottom {
  margin-bottom: var(--sbt-spacing-md);
}

.caption {
  margin-bottom: var(--sbt-spacing-md);
  margin-top: var(--sbt-spacing-2xs);
}