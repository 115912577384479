@use '~@sbt-web/ui/common/css/variables';

.contactButton {
  flex: 1;

  @media (min-width: variables.$tablet_min_width) {
    flex: 0 0 180px;
  }
}

.switchedTouchPoints {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: var(--sbt-spacing-sm);

  button:nth-child(1) {
    flex: 0;
  }

  button:nth-child(2) {
    flex: 1;
  }

  @media (min-width: variables.$tablet_min_width) {
    width: auto;

    button:nth-child(2) {
      flex: 0 0 180px;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.skeleton {
  flex: 1;
  height: var(--sbt-button-height-lg);
  border-radius: var(--sbt-corner-md);
  background: linear-gradient(
    90deg,
    var(--sbt-primary-color-l3) 25%,
    var(--sbt-primary-color-l2) 50%,
    var(--sbt-primary-color-l3) 75%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  cursor: progress;
}
