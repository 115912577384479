@use '~@sbt-web/ui/common/css/variables';

.forwardText {
  color: var(--sbt-secondary-text-color);
}
.recallText {
  display: flex;
  align-items: center;
}

.marginTop {
  margin-top: var(--sbt-spacing-ml);
}
