@use '~@sbt-web/ui/common/css/variables';

.gallery-divider {
  border-color: #fff;
  opacity: var(--sbt-opacity4);
  width: calc(100% - (2 * var(--sbt-spacing-lg)));
  z-index: 2;

  position: relative;
  /* The padding plus the fixed height of the thumbnails. */
  bottom: calc((var(--sbt-spacing-sm) * 2) + 50px);
  left: var(--sbt-spacing-lg);
}

.full-size {
  position: fixed;
  inset: 0;
  height: 100%;
  z-index: 1000;
}

.close-dialog {
  position: absolute;
  top: var(--sbt-spacing-lg);
  right: var(--sbt-spacing-lg);
  color: var(--sbt-secondary-text-color);
  background-color: var(--sbt-background-root);
  opacity: var(--sbt-opacity4);
  border-radius: 100%;
  border-width: 0;
  cursor: pointer;
  &:hover {
    background-color: var(--sbt-background-default);
    color: var(--sbt-secondary-text-color);
  }

  @media (max-width: variables.$mobile_max_width) {
    max-width: var(--sbt-layout-md);
    order: 1;
    height: var(--sbt-button-height-sm);
    width: var(--sbt-button-height-sm);
    top: var(--sbt-spacing-sm);
    right: var(--sbt-spacing-sm);
  }

  @media (min-width: variables.$tablet_min_width) {
    order: initial;
  }

  &:focus,
  &:hover {
    color: var(--sbt-secondary-icon-color);
  }

  svg,
  svg * {
    fill: currentColor;
  }
}
