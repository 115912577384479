@use '~@sbt-web/ui/common/css/variables.scss';

.frame {
  width: 100%;
  height: 280px;
  margin-top: var(--sbt-spacing-md);
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-md);

  // placeholder while lazyloading
  background: variables.$neutralGrey5;
  background-size: 200%;
  background-image: linear-gradient(
    90deg,
    rgba(variables.$neutralGrey5, 1) 0%,
    rgba(variables.$neutralGrey5, 1) 30%,
    rgba(variables.$white, 1) 50%,
    rgba(variables.$neutralGrey5, 1) 70%,
    rgba(variables.$neutralGrey5, 1) 100%
  );
  animation: loading 1.5s ease-in-out infinite;

  @keyframes loading {
    from {
      background-position: 100%;
    }
    to {
      background-position: -100%;
    }
  }

  @media print, (prefers-reduced-motion: reduce) {
    background: variables.$neutralGrey5;
  }

  @media (min-width: variables.$tablet_min_width) {
    height: 376px;
  }
}

.address {
  margin-top: var(--sbt-spacing-2xs);
  display: flex;

  img {
    height: var(--sbt-layout-xs);
    width: var(--sbt-layout-xs);
    margin-right: var(--sbt-spacing-2xs);
  }
}
