.details {
  border-bottom: var(--sbt-border-default);

  h2, h3 {
    padding: 0;
    font-weight: 600;
  }

  &[open] .arrow-icon {
    transform: rotate(180deg);
  }
}

.summary {
  height: 50px;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: 0 var(--spacing-lg);
  cursor: pointer;
  list-style: none;
  font-size: 14px;
  color: var(--sbt-primary-text-color);

  &::-webkit-details-marker {
    display: none;
  }
}

.arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.content {
  padding: var(--spacing-md) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
}
