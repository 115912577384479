@use '~@sbt-web/ui/common/css/variables';

.general-info {
  display: flex;
  flex-flow: column nowrap;
}

.ad-info-divider {
  align-self: stretch;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: variables.$desktop_min_width) {
  .ad-info {
    flex-grow: 1;
  }
}
