@import '~@sbt-web/ui/common/css/variables.scss';

.title {
  margin-bottom: $spacing_xs;
}

.dialogOpener {
  color: var(--sbt-secondary-text-color);
  margin-bottom: $spacing_sm;
  padding: 0;
  cursor: pointer;

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  > * + * {
    margin-left: $spacing_xs;
  }
}

.infocarSection + .infocarSection {
  margin-top: $spacing_xl;
}

.infocarSectionHeading {
  padding: 0;
  margin: 0 0 $spacing_xs 0;
}

.infocarFeatureList {
  column-count: 2;
  column-width: 300px;
}

.infocarFeatureListItem {
  display: flex;
  margin-bottom: $spacing_2xs;
}

.infocarFeatureElement {
  display: inline-block;
  width: 50%;
}

.infocarFeatureLabel {
  color: var(--sbt-secondary-text-color);
  vertical-align: top;
}

/* Style for the informational dialog */

.disclaimer-badge {
  margin: var(--sbt-spacing-md) 0;
}

.disclaimer-clause + .disclaimer-clause {
  margin-top: var(--sbt-spacing-lg);
}

@media (min-width: $desktop_min_width) {
  .disclaimer-clause {
    max-width: min(50vw, 80em);
  }
}
