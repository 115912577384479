.user-details-name {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.company-badge {
  width: fit-content;
}

.shop-with-contact {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-xs);
}

.shop-details-wrapper {
  align-items: flex-start;
  color: var(--sbt-primary-text-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.shop-link:hover {
  color: var(--sbt-secondary-text-color);
  text-decoration: underline;
}
