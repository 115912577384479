@use 'sass:math';
@use '~@sbt-web/ui/common/css/variables.scss' as v;
@use '~@sbt-web/ui/common/css/mixins.scss';

.shop-box-bottom {
  @include mixins.flexbox();
  @include mixins.flex-wrap(wrap);
  @include mixins.align-items(flex-start);
  @media (min-width: v.$desktop_min_width) {
    @include mixins.justify-content(space-between);
  }
  border: 1px solid v.$neutralGrey4;
  border-radius: var(--sbt-corner-md);
  padding: var(--sbt-spacing-lg);
}

@media (max-width: v.$mobile_max_width) {
  .shop-box-bottom > button {
    width: 100%;
    margin-top: v.$spacing_sm;
    > span {
      width: 100%;
    }
  }
}

@mixin image-size($height) {
  $image_ratio: math.div(4, 3);
  width: $height * $image_ratio;
  height: $height;
}

.main_data {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-md);
}

.main_info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-md);

  &:empty {
    display: none;
  }
}

.google-review {
  margin-top: var(--sbt-spacing-2xs);
}

.logo {
  @include mixins.flexbox;
  @include mixins.align-items(center);
}

.company_name {
  @media (max-width: v.$tablet_max_width) {
    margin-left: var(--sbt-spacing-sm);
  }

  @media (min-width: v.$desktop_min_width) {
    margin-left: var(--sbt-spacing-md);
  }

  h6 {
    margin-top: var(--sbt-spacing-2xs);
  }
}
.address {
  .body_text {
    color: var(--sbt-secondary-text-color);
  }
}

.website {
  &_link {
    color: var(--sbt-secondary-text-color);
    display: block;
    text-decoration: none;

    &:visited,
    &:hover,
    &:active {
      color: var(--sbt-secondary-text-color);
    }
    &:active {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.shop_image {
  @include mixins.flexbox;
  @include mixins.align-items(center);
  @include mixins.justify-content(center);
  border: 1px solid var(--sbt-border-color);
  background-color: var(--sbt-background-root);

  @include image-size(v.$layout_lg);

  @media (min-width: v.$desktop_min_width) {
    @include image-size(v.$layout_xl);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.socialization {
  @include mixins.flex(1, 100%);
  @include mixins.flexbox();
  @include mixins.flex-wrap(wrap);
  @include mixins.justify-content(space-between);
  @include mixins.align-items(center);
  margin-top: var(--sbt-spacing-lg);

  @media (min-width: v.$desktop_min_width) {
    margin-top: 0;
    @include mixins.flex(0, auto);
    display: block;
  }
}

.anchor_button {
  background-color: var(--sbt-background-default);
  border: 1px solid var(--sbt-border-color);
  border-radius: var(--sbt-corner-md);
  color: var(--sbt-primary-color);
  float: right;
  padding: var(--sbt-spacing-2xs) var(--sbt-spacing-xs);

  &:hover,
  &:active {
    text-decoration: none;
    border-color: var(--sbt-primary-color);
    background-color: var(--sbt-primary-color-l3);
  }
}

.online_ads {
  .body_text {
    color: var(--sbt-secondary-text-color);
  }
}

.opening_time_caption {
  margin-bottom: 0;
}

.socializationB {
  @include mixins.flex(1, 100%);
  @include mixins.flexbox();
  @include mixins.flex-wrap(wrap);
  @include mixins.justify-content(flex-start);
  @include mixins.align-items();
  @include mixins.flex-direction(column);
  margin-top: var(--sbt-spacing-lg);

  gap: var(--sbt-spacing-md);

  @media (min-width: v.$desktop_min_width) {
    margin-top: 0;
    @include mixins.flex(0, auto);
  }

  .actions {
    @include mixins.flex(1, 100%);
    @include mixins.flexbox();
    @include mixins.flex-direction(column);
    gap: var(--sbt-spacing-md);

    @media (max-width: v.$mobile_max_width) {
      flex-direction: row;

      & > * {
        @include mixins.flexbox();
        @include mixins.justify-content(center);
        @include mixins.flex(1, 100%);
      }
    }
  }
}
