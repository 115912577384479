@import '~@sbt-web/ui/common/css/variables';

.quality-seal-button {
  cursor: pointer;
  padding: 0;
}

@media (max-width: $tablet_max_width) {
  .quality-seal-video-frame {
    width: 100%;
  }
}

.quality-seal-dialog-link {
  display: block;
  margin: var(--sbt-spacing-md) 0;
  text-align: center;
}
