@use '~@sbt-web/ui/common/css/variables.scss' as v;

$online_status_color: #008f7f;

.presence_status {
  display: flex;
  margin-top: var(--sbt-spacing-3xs);
  &:before {
    content: '';
    width: 9px;
    height: 9px;
    display: block;
    margin: 4px var(--sbt-spacing-xs) 0 2px;
    border-radius: 50%;
    background-color: var(--sbt-background-default);
    border: 2px solid v.$neutralGrey2;
    flex: 0 0 auto;
  }
  &.online {
    &:before {
      background-color: $online_status_color;
      border-color: $online_status_color;
    }
  }
}
