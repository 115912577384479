@use '~@sbt-web/ui/common/css/variables';

#attachment_component {
  margin-top: var(--sbt-spacing-md);
}

.container {
  display: flex;
  align-items: center;
}
.fileInput {
  display: none;
}
.fakeFileInput {
  padding: variables.$input_padding_md;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: variables.$secondary_font_size;
  line-height: 20px;
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  height: var(--sbt-input-height-md);
  color: var(--sbt-primary-color);
  &:hover {
    color: var(--sbt-primary-color-l1);
  }
  &.active {
    color: var(--sbt-primary-color-d1);
  }
  &.withFile {
    color: var(--sbt-primary-text-color);
    font-weight: 300;
    display: block;
  }
  svg {
    fill: currentColor;
    margin-right: var(--sbt-spacing-xs);
  }
}
