@use '~@sbt-web/ui/common/css/variables.scss';

.detail-component {
  margin-top: var(--sbt-spacing-xl);
}

.detail-component:first-child {
  margin-top: 0;
}

.gallery {
  position: relative;
  margin-bottom: var(--sbt-spacing-2xs);
}

.section-divider {
  margin-top: var(--sbt-spacing-2xl);
  margin-right: auto;
}

@media (max-width: variables.$tablet_max_width) {
  .detail-container {
    padding-left: var(--sbt-spacing-md);
    padding-right: var(--sbt-spacing-md);
  }
}

@media (min-width: variables.$desktop_min_width) {
  .detail-container {
    padding-left: var(--sbt-spacing-lg);
    padding-right: var(--sbt-spacing-lg);
    width: variables.$desktop_min_width;
  }

  .detail-container::after {
    content: '';
    clear: both;
    display: block;
  }

  .detail-component {
    width: 620px;
  }

  .navigation-detail-container {
    width: 100%;
  }

  .gallery,
  .description,
  .vehicle-history {
    display: inline-block;
  }

  .ad-info-container,
  .skyscraper {
    margin-left: 18px;
    width: 300px;
    vertical-align: top;
  }

  .skyscraper {
    display: inline-block;
  }

  .ad-info-container.ad-info-override {
    display: inline-flex;
  }

  .ad-info-container {
    // This is defined in CarouselCell.tsx
    height: 466px;
  }

  /* No reset */

  /* End no reset */

  .skyscraper {
    float: right;
  }
}

@supports (display: grid) and (display: contents) {
  /* Compat reset start */
  .detail-component {
    margin-top: unset;
  }

  .section-divider {
    margin-right: unset;
  }
  /* Compat reset end */

  .detail-container {
    display: grid;
    grid-column-gap: var(--gutter-width, var(--sbt-spacing-lg));
    column-gap: var(--gutter-width, var(--sbt-spacing-lg));
    grid-row-gap: var(--sbt-spacing-md);
    row-gap: var(--sbt-spacing-md);
    grid-auto-flow: row;
  }

  .detail-component {
    grid-column-start: 1;
  }

  .section-divider {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: variables.$mobile_max_width) {
    .detail-container {
      --number-of-columns: 4;
    }
  }

  @media (min-width: variables.$tablet_min_width) and (max-width: variables.$tablet_max_width) {
    .detail-container {
      --number-of-columns: 8;
    }
  }

  @media (max-width: variables.$tablet_max_width) {
    .detail-container {
      --gutter-width: var(--sbt-spacing-md);
      grid-template-columns: repeat(var(--number-of-columns), 1fr);
    }

    .detail-component {
      grid-column-end: span var(--number-of-columns);
    }
  }

  @media (min-width: variables.$desktop_min_width) {
    /* Compat reset start */
    .detail-container {
      width: unset;
    }

    .detail-container::after {
      content: unset;
      clear: unset;
      display: unset;
    }

    .detail-component {
      width: unset;
    }

    /*
        This must be redeclared here to
        beat the unset rule above
      */
    .section-divider {
      width: 100%;
    }

    .gallery,
    .ad-info-container,
    .description,
    .vehicle-history,
    .skyscraper {
      display: unset;
    }

    .ad-info-container,
    .skyscraper {
      margin-left: unset;
      vertical-align: unset;
    }

    .ad-info-container {
      box-sizing: unset;
      height: unset;
    }

    .skyscraper {
      float: unset;
    }

    /* Compat reset end */

    .detail-container {
      --content-end: 8;
      --column-width: 60px;
      --gutter-width: var(--sbt-spacing-lg);
      --number-of-columns: 12;

      grid-template-columns: repeat(
        var(--number-of-columns),
        var(--column-width)
      );
      grid-template-rows:
        min-content repeat(var(--right-block-row-span), min-content)
        1fr;
      /* We should have explicit grids, but if not the new rows should only take the space needed. */
      grid-auto-rows: min-content;
    }

    .detail-component {
      grid-column-end: span var(--content-end);
    }

    .navigation-detail-container {
      grid-column-end: -1;
      width: unset;
    }

    .right-container {
      grid-column-start: calc(var(--content-end) + 1);
      grid-column-end: -1;
      grid-row: 2 / -1;
    }

    .ad-info-container {
      min-height: 466px;
      margin-bottom: var(--sbt-spacing-xl);
    }

    .ad-info-container.ad-info-override {
      display: flex;
    }
  }
}
