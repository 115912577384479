@use '~@sbt-web/ui/common/css/variables';
@use '~@sbt-web/ui/common/css/mixins';

.iframe-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buy-dialog {
  @include mixins.flexbox;
  div:nth-of-type(1) {
    border-radius: var(--sbt-corner-lg);

    a {
      @include mixins.flexbox;
      @include mixins.align-items(center);
      padding: var(--sbt-spacing-xs);
      margin: var(--sbt-spacing-xs);
    }

    div:nth-of-type(1) {
      h3 {
        height: var(--sbt-spacing-xl);
        left: var(--sbt-spacing-md);
        right: 88px;
        top: calc(50% - 24px / 2 + 1px);
        padding: 0;

        font-style: normal;
        @include mixins.token-h6;
      }
      @include mixins.align-content(flex-start);
      @include mixins.flexbox;
      padding: var(--sbt-spacing-md) 0 var(--sbt-spacing-md)
        var(--sbt-spacing-md);
      margin: 0 !important;
      border-bottom: var(--sbt-border-default);
      border-radius: 0px;
    }
    .iframe {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .some > div:nth-of-type(1) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    .iframe {
      width: 100%;
    }
  }
}
