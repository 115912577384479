@use '~@sbt-web/ui/common/css/variables.scss' as v;

.title {
  margin-block-end: var(--sbt-spacing-md);
}

.placeholder {
  color: v.$neutralGrey3;
}

.main-features-container {
  margin-block: var(--sbt-spacing-sm);

  --column-count: 4;
  display: grid;
  grid-template-columns: repeat(var(--column-count), minmax(130px, 1fr));
  gap: var(--sbt-spacing-md);
}

.main-feature {
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-sm);
}

@media (max-width: v.$mobile_max_width) {
  .main-features-container {
    --column-count: 2;
    column-gap: var(--sbt-spacing-xs);
    row-gap: var(--sbt-spacing-md);
  }
}
