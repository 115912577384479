@use '~@sbt-web/ui/common/css/variables';

$animationHeight: 735px;

.formWrapper {
  display: flex;
  flex-direction: column;
  @media (min-width: variables.$tablet_min_width) {
    border: 1px dashed variables.$neutralGrey3;
    border-radius: 5px;
    position: relative;
  }
  button.closeButton {
    color: var(--sbt-secondary-text-color);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    &:hover {
      color: variables.$neutralGrey3;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.container {
  padding: var(--sbt-spacing-2xl) var(--sbt-spacing-md);
  background-color: var(--sbt-background-default);
  position: relative;
  overflow: hidden;
  transition:
    max-height ease 0.3s,
    padding ease 0.3s;
  max-height: $animationHeight;
  @media (min-width: variables.$tablet_min_width) {
    padding: var(--sbt-spacing-xl) var(--sbt-spacing-lg);
  }
  &.hide {
    padding: 0;
    max-height: 0;
  }
  textarea {
    height: 100px;
  }
}
.form {
  margin-top: var(--sbt-spacing-md);
}
.submitButton {
  margin-top: var(--sbt-spacing-lg);
  width: 100%;
  justify-content: center;
  @media (min-width: variables.$tablet_min_width) {
    max-width: 68px;
  }
}
