@use '~@sbt-web/ui/common/css/variables' as v;

.carousel {
  --sbt-carousel-background-color: #000000;
}

.carousel-cell {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  /* This class is legacy but is used by an ADV vendor as a locator */
  &:global(.flickity-viewport) {
    touch-action: auto;
  }
}

.image {
  max-width: 100%;
  object-fit: contain;
}

@media (max-width: v.$mobile_max_width) {
  .image {
    height: 270px;
  }
}

@media (min-width: v.$tablet_min_width) {
  .image {
    height: 466px;
  }
}

.counter {
  background-color: rgba(0, 0, 0, var(--sbt-opacity4));
  border-radius: var(--sbt-corner-sm);
  color: #fff;
  padding: 0 var(--sbt-spacing-xs);
  text-align: center;
  width: max-content;
  z-index: 1;

  position: absolute;
  top: var(--sbt-spacing-md);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.full-screen-carousel,
.full-screen-picture,
.full-screen-figure,
.full-screen-img {
  height: 100%;
}

.full-screen-picture {
  width: 100%;
  display: flex;
  justify-content: center;
}

.full-screen-img {
  object-fit: contain;
}
