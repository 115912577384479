@use '~@sbt-web/ui/common/css/variables.scss';

$share_svg_mobile: 24px;
$share_svg_desktop: 16px;
$abuse_svg_mobile: 24px;
$abuse_svg_desktop: 16px;

.ad-bottom-container {
  background-color: var(--sbt-background-root);
  padding: var(--sbt-spacing-xs) var(--sbt-spacing-lg);
  display: flex;
  align-items: center;
}

.ad-bottom__buttons {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ad-bottom__ad-id {
  color: var(--sbt-secondary-text-color);
  margin-left: var(--sbt-spacing-sm);
}

@media (max-width: variables.$desktop_min_width) {
  .ad-bottom-container {
    flex-direction: column;
  }
  .ad-bottom__buttons {
    width: 100%;
    margin-bottom: 0;
    margin-top: var(--sbt-spacing-xs);
  }
  .ad-bottom__ad-id {
    margin-left: 0;
    margin-bottom: var(--sbt-spacing-sm);
    margin-top: var(--sbt-spacing-lg);
  }
  .ad-bottom__buttons :global(button) {
    padding-top: var(--sbt-spacing-md);
    padding-bottom: var(--sbt-spacing-xs);
    display: flex;
    justify-content: center;
    width: 50%;
  }
  .ad-bottom__buttons :global(button:first-child) {
    border-right: 1px solid variables.$neutralGrey4;
  }
}
