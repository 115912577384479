@use '~@sbt-web/ui/common/css/variables.scss' as v;

.buttonAndContainer {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  &.small {
    align-items: center;
  }
}
.name {
  color: var(--sbt-primary-text-color);
}
.message {
  color: var(--sbt-secondary-text-color);
}
.text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rating {
  margin-bottom: var(--sbt-spacing-3xs);
}
.contactButton {
  margin-top: var(--sbt-spacing-xs);
  > span {
    width: 100%;
  }
}
