@use '~@sbt-web/ui/common/css/variables' as v;

.shipping-options {
  margin-top: var(--sbt-spacing-lg);
}

.shipping-cost-wrapper {
  display: flex;
  gap: var(--sbt-spacing-md);
}

.icon-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.shipping-cost {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.name-and-price {
  display: flex;
  justify-content: space-between;
}

.cost-with-icon {
  display: flex;
  gap: var(--sbt-spacing-xs);
}

#leaf-icon {
  align-self: center;
  margin-left: var(--sbt-spacing-2xs);
}

.shipping-text {
  color: var(--sbt-secondary-text-color);
}

.shipping-option-separator {
  margin: var(--sbt-spacing-xs) 0;
  overflow: hidden;
  text-align: center;
  
  &::before,
  &::after {
    background-color: var(--sbt-border-color);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  
  &::before {
    right: calc(0.5em + var(--sbt-spacing-2xs));
    margin-left: -50%;
  }
  
  &::after {
    left: calc(0.5em + var(--sbt-spacing-2xs));
    margin-right: -50%;
  }
}

.shipping-type {
  margin-top: var(--sbt-spacing-lg);
}

.shipping-icons-container {
  display: flex;
  gap: var(--sbt-spacing-2xs);
  margin-top: var(--sbt-spacing-xs);
}
