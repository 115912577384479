@use '~@sbt-web/ui/common/css/variables' as v;

.title {
  margin-bottom: var(--sbt-spacing-lg);
}

.category-list {
  display: grid;
  column-gap: var(--sbt-spacing-lg);
  row-gap: var(--sbt-spacing-sm);

  @media (max-width: v.$mobile_max_width) {
    grid-template-columns: 1fr;
  }

  @media (min-width: v.$tablet_min_width) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

.category-row {
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-md);
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-xs);
  padding: var(--sbt-spacing-xs);
}

.category-name {
  margin-bottom: var(--sbt-spacing-2xs);
}

.category-bar {
  height: var(--sbt-spacing-xs);
  background-color: var(--sbt-secondary-icon-color);
  border-radius: var(--sbt-corner-lg);
  /* This rule aligns the minimum diplayed and shows at minimum a circle */
  min-width: var(--sbt-corner-lg);
}
