@use '~@sbt-web/ui/common/css/variables.scss' as v;

$smallSize: 40px;
$online_status_color: #008f7f;

.rounded_user_badge {
  border-radius: 50%;
  text-align: center;
  color: var(--sbt-secondary-text-color);
  background-color: v.$neutralGrey4;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sbt-layout-md);
  height: var(--sbt-layout-md);
  margin: var(--sbt-spacing-2xs) var(--sbt-spacing-lg) var(--sbt-spacing-2xs) 0;
  position: relative;
  flex: 0 0 var(--sbt-layout-md);
  &:hover {
    text-decoration: none;
  }
  &.small {
    width: $smallSize;
    height: $smallSize;
    margin-right: var(--sbt-spacing-sm);
    flex: 0 0 $smallSize;
  }
}
.picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.presence_online_status {
  position: absolute;
  width: 12px;
  height: 12px;
  display: block;
  background-color: $online_status_color;
  border-radius: 50%;
  bottom: 0;
  right: 0;
}
