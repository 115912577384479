@use '~@sbt-web/ui/common/css/variables.scss' as v;

$column_width: 280px;

.feature-list {
  column-count: 2;
  /* This is defined in grid.module.scss */
  column-gap: var(--gutter-width, 20px);
  column-width: $column_width;
}

/* The spacing breaks down at the column_collapse point, which is smaller than
 * tablet so the gutter width is always this value.
 * You can't use `var` in media queries.
 */
$tablet_gutter_width: v.$spacing_md;
$page_padding: 16px;

/* This is the width when the columns switch from 1 to 2 */
$column_collapse: calc(
  (2 * $column_width) + v.$spacing_md + (2 * $page_padding)
);

.feature-list.list {
  max-width: 50%;

  /* There comes a point where the layout can take on 2 columns.
   * Below that point the tables should behave the same, but above it
   * the single column must be limited to 50% width.
   */
  @media (max-width: $column_collapse) {
    column-count: 1;
    max-width: unset;
  }
}

.feature {
  align-items: flex-start;
  display: flex;
  margin-bottom: var(--sbt-spacing-xs);
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.feature .label,
.feature .value {
  display: inline-block;
  width: 50%;
}

.feature .label {
  color: var(--sbt-secondary-text-color);
}

.feature .value {
  margin-left: var(--sbt-spacing-xs);
}
