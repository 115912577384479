@use '~@sbt-web/ui/common/css/variables.scss' as v;

.section-title {
  margin-bottom: var(--sbt-spacing-md);
}

.section-subtitle {
  margin-bottom: var(--sbt-spacing-md);
  margin-top: var(--sbt-spacing-md);
}

#detail-chip-container {
  display: flex;
  flex-flow: row wrap;
  gap: var(--sbt-spacing-xs);

  /* If the gap doesn't work, pad the chips */
  &.gap-fallback .static-chip {
    margin: 0 var(--sbt-spacing-sm) var(--sbt-spacing-sm) 0;
  }
}

.static-chip {
  align-items: center;
  background-color: var(--sbt-background-root);
  border-radius: var(--sbt-corner-lg);
  display: inline-flex;
  flex-shrink: 0;
  height: var(--sbt-layout-sm);
  padding: 0 var(--sbt-spacing-xs);
}
