.imageFrame {
  display: flex;
  height: 48px;
  aspect-ratio: 4/3;
  margin-right: var(--sbt-spacing-xs);
  justify-content: center;
  align-items: center;
  background-color: var(--sbt-background-root);
  border: var(--sbt-border-default);
}

.noImage {
  display: block;
  width: 40%;
  border: red;
}
