.container {
  display: flex;
  flex-direction: column;
}

.rating {
  display: flex;
  align-items: center;
}

.points {
  font-size: 14px;
  font-weight: 600;
  margin-right: var(--sbt-spacing-2xs);
}

.googleLogo {
  margin-left: var(--sbt-spacing-xs);
}

.poweredby {
  font-size: 14px;
  color: var(--sbt-secondary-text-color);
}
