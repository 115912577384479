@use '~@sbt-web/ui/common/css/variables.scss' as v;

.ad-abuse {
  .ad-button-label {
    padding-left: var(--sbt-spacing-sm);
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
    @media (max-width: v.$mobile_max_width) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#loader {
  width: var(--sbt-layout-sm);
  height: var(--sbt-layout-sm);
  border: 3px solid var(--sbt-primary-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
  margin: var(--sbt-spacing-sm) auto;
  align-self: center;
}
