@use '~@sbt-web/ui/common/css/variables';
@use '~@sbt-web/ui/common/css/mixins';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: var(--sbt-spacing-xs);

  border-bottom: var(--sbt-border-default);
  padding: var(--sbt-spacing-xs) var(--sbt-spacing-lg);
  overflow: hidden;
  height: calc(
    var(--sbt-spacing-3xl) + 1px
  ); // this is necessary to avoid horrible glitch with height auto and border-bottom
  position: relative;

  .title {
    color: var(--sbt-secondary-text-color);
    /* Force the same line height as the chips for alignment */
    line-height: 24px;
  }

  a {
    text-decoration: none;
    background-color: var(--sbt-background-default);
    border-radius: var(--sbt-corner-md);

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.chips-wrapper {
  /* This allows the content to grow flexibly based on the remaining space */
  flex: 1;

  /* This sets it as a container for the chips */
  display: flex;
  flex-flow: row wrap;

  column-gap: var(--sbt-spacing-2xs);
  row-gap: var(--sbt-spacing-xs);
}

#toggle-checkbox {
  display: none;
}

.wrapper:has(#toggle-checkbox:checked) {
  height: auto;
  transition-timing-function: ease-in-out;
  transition-duration: 0.01s;
  transition-property: height;

  > .toggle-icon {
    align-self: flex-end;
    margin-bottom: var(--sbt-spacing-xs);
    transform: scaleY(-1);
  }
}

.toggle-wrapper {
  @include mixins.flexbox;
  @include mixins.justify-content(center);
  @include mixins.align-items(center);

  position: absolute;
  right: var(--sbt-spacing-lg);
  padding-left: var(--sbt-spacing-sm);
  height: 100%;
  bottom: 0;

  > label {
    cursor: pointer;
    width: var(--sbt-icon-sm);
    height: var(--sbt-icon-sm);
  }
}

@media (max-width: variables.$mobile_max_width) {
  .wrapper {
    z-index: auto;

    .extra-label {
      display: none;
    }
  }
}

@media (min-width: variables.$tablet_min_width) {
  .header-link:nth-child(2):nth-last-child(n + 6) ~ .toggle-icon {
    display: flex;
  }
}
