@use '~@sbt-web/ui/common/css/variables.scss' as v;

.navigation {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--sbt-spacing-md);
}

@media (min-width: v.$tablet_min_width) {
  .navigation {
    flex-direction: row;
    align-items: center;
    gap: var(--sbt-spacing-lg);
  }
}
