@use '~@sbt-web/ui/common/css/variables';

.body {
  margin: var(--sbt-spacing-lg) 0 0;

  .title {
    margin-bottom: var(--sbt-spacing-xs);
  }
}

.description {
  margin: var(--sbt-spacing-lg) auto;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: var(--sbt-spacing-sm);
}
