@use '~@sbt-web/ui/common/css/variables.scss' as v;

.container {
  border-bottom: none;
}

.categories-list {
  display: grid;
  grid-template-columns: repeat(var(--sitemap-columns), 1fr);
  column-gap: var(--sbt-spacing-lg);
  row-gap: var(--sbt-spacing-lg);
}

@media (max-width: 400px) {
  .categories-list {
    --sitemap-columns: 2;
  }
}

@media (min-width: 400px) and (max-width: v.$mobile_max_width) {
  .categories-list {
    --sitemap-columns: 3;
  }
}

@media (min-width: v.$tablet_min_width) {
  .categories-list {
    --sitemap-columns: 6;
  }
}

.category {
  list-style-type: none;
  margin-top: var(--sbt-spacing-xs);
}

.category-name {
  padding: var(--sbt-spacing-2xs) 0;
}

.title a,
.sub-list a {
  color: var(--sbt-primary-text-color);
}

.capitalize::first-letter {
  text-transform: capitalize;
}
