@import '~@sbt-web/ui/common/css/variables';

.quality-seal-heading {
  margin-bottom: $spacing_md;
}

.quality-seal {
  border: 1px solid $neutralGrey4;
  border-radius: $corner_md;
  display: inline-block;
}

.quality-seal-caption {
  align-items: center;
  display: inline-flex;
}

.quality-seal-caption-text {
  text-transform: uppercase;
  white-space: pre-line;
}

.brand-seal {
  display: inline-block;
}

.quality-seal-caption,
.brand-seal {
  vertical-align: middle;
}

.quality-seal-name {
  font-weight: 600;
  line-height: 32px; // This is the height of the logo in the JSX
}

@media (max-width: $mobile_max_width) {
  .quality-seal-caption {
    margin-left: $spacing_sm;
    margin-right: $spacing_xs;
  }

  .check-icon {
    margin-right: $spacing_xs;
  }

  .brand-seal {
    margin-bottom: $spacing_sm;
    margin-right: $spacing_sm;
    margin-top: $spacing_sm;
  }
}

@media (min-width: $tablet_min_width) {
  .quality-seal-caption {
    margin-left: $spacing_lg;
    margin-right: $spacing_md;
  }

  .check-icon {
    margin-right: $spacing_md;
  }

  .brand-seal {
    margin-bottom: $spacing_lg;
    margin-right: $spacing_lg;
    margin-top: $spacing_lg;
  }
}
