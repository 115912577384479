@use '~@sbt-web/ui/common/css/variables' as v;

.payment-methods {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-md);
}

.payment-info {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-xs);
}

.subtitle-with-icon {
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-md);
  margin-bottom: var(--sbt-spacing_xs);
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost-with-icon {
  display: flex;
  gap: var(--sbt-spacing-xs);
}
