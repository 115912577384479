@use '~@sbt-web/ui/common/css/variables.scss' as v;

.vehicle-history-title {
  margin-bottom: var(--sbt-spacing-xl);
}

.vehicle-history-container {
  margin-top: var(--sbt-layout-xs);
}

.vehicle-history-adv {
  margin-bottom: var(--sbt-layout-xs);
}
