@use '~@sbt-web/ui/common/css/variables.scss' as v;

.links {
  display: grid;

  /* The columns have uniform width */
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
  column-gap: var(--sbt-spacing-md);
  /* The rows have auto height */
  grid-auto-rows: auto;
  row-gap: var(--sbt-spacing-xs);
}

@media (max-width: v.$mobile_max_width) {
  .links {
    --grid-column-count: 2;
  }
}

@media (min-width: v.$tablet_min_width) {
  .links {
    --grid-column-count: 6;
  }
}

