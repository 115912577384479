@use '~@sbt-web/ui/common/css/variables';

$stickyContainerHeight: 72px;

.stickyContainer {
  display: flex;
  justify-content: center;
  background-color: var(--sbt-background-default);
  left: 0;
  width: 100%;
  position: fixed;
  border: var(--sbt-border-default);
  z-index: 300;
  height: $stickyContainerHeight;
  box-shadow: var(--sbt-shadow-sticky);

  bottom: 0;
  transition: bottom 0.3s ease-in-out;
  &.hide {
    bottom: -$stickyContainerHeight;
  }
}

.stickyContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: var(--sbt-page-width);
  height: 100%;
  padding: 0 var(--sbt-spacing-lg);
  gap: var(--sbt-spacing-sm);
}

.nameAndPrice {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: variables.$default_font_size;
  padding-right: var(--sbt-spacing-xs);
  gap: var(--sbt-spacing-3xs);
}

.cta {
  width: 180px;
}
