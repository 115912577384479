.costWithDiscount {
  display: flex;
  gap: var(--sbt-spacing-xs);
}

.strikePrice {
  text-decoration: line-through;
  color: var(--sbt-secondary-text-color);
  align-self: end;
}
