@use '~@sbt-web/ui/common/css/variables.scss' as v;

.action-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.label-category {
  flex-shrink: 0;
}

.buttons {
  display: flex;
}

.buttons :global(button) {
  display: block;
  position: static;
  margin: 0;
}

.buttons .share-button,
.buttons .share-button:hover {
  color: var(--sbt-secondary-text-color);
}

.buttons button.share-button {
  border: 0;
  margin-right: var(--sbt-spacing-2xs);
  @media (min-width: v.$desktop_min_width) {
    height: var(--sbt-icon-md);
  }
}

.buttons :global(button):last-child {
  margin-right: 0;
  padding: 0 var(--sbt-spacing-xs);
  @media (min-width: v.$desktop_min_width) {
    height: var(--sbt-icon-md);
  }
}
