@use '~@sbt-web/ui/common/css/variables.scss' as v;

.ad-info__top-caption-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--sbt-spacing-sm);
}

.ad-info__listing-info {
  display: flex;
  align-items: center;
  //FIXME FavoriteCounter must have a css as prop:
  > div {
    border-left: var(--sbt-border-default);
    padding-left: var(--sbt-spacing-xs);
    margin-left: var(--sbt-spacing-xs);
  }
}

.ad-info__id {
  align-self: flex-end;
  color: var(--sbt-secondary-text-color);
}

.title {
  color: var(--sbt-primary-text-color);
  margin-bottom: var(--sbt-spacing-2xs);
  overflow-wrap: break-word;
}

.price-and-warranty {
  display: flex;
  gap: var(--sbt-spacing-sm);
}

.price {
  font-size: 24px;
  color: var(--sbt-primary-text-color);
}

.location {
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-xs);
  margin-bottom: var(--sbt-spacing-sm);
}

.locationText {
  color: var(--sbt-secondary-text-color);
}

.buyButton {
  display: flex;
  margin-top: var(--sbt-spacing-md);
  
  :first-child {
    flex: 1;
  }
}

.paypal-section {
  margin-top: var(--sbt-spacing-sm);

  @media (max-width: v.$mobile_max_width) {
    margin-top: var(--sbt-spacing-md);
  }
}

.sold-box {
  margin-top: var(--sbt-spacing-md);
}

/* If the UsefulLinks render, we add the space above them */
@media (min-width: v.$desktop_min_width) {
  .useful-links-wrapper :global(#usefullinks_wrapper) {
    margin-bottom: var(--sbt-spacing-sm);
    margin-top: var(--sbt-spacing-sm);
  }
}
@media (max-width: v.$desktop_min_width) {
  .useful-links-wrapper :global(#usefullinks_wrapper) {
    margin-bottom: var(--sbt-spacing-lg);
    margin-top: var(--sbt-spacing-sm);
  }
}

.campaign-banner-container {
  margin-top: var(--sbt-spacing-md);
}
