.number {
  font-size: 30px;
  font-style: normal;
  line-height: normal;
  text-align: center;
}

.phoneButton {
  flex: 0;
}
