@use '~@sbt-web/ui/common/css/variables.scss' as v;

.seller-info-content {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-md);
}

.action-buttons {
  margin-bottom: var(--sbt-spacing-3xl);
}

.buttons-box {
  display: flex;
  gap: var(--sbt-spacing-sm);
  @media (min-width: v.$tablet_min_width) {
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: v.$tablet_max_width) {
  .stickiness {
    background-color: var(--sbt-background-default);
    bottom: 0;
    box-shadow: 0 -10px 20px 5px #fff;
    display: flex;
    margin: 0 calc(var(--sbt-spacing-md) * -1);
    padding: var(--sbt-spacing-xs) var(--sbt-spacing-md);
    position: sticky;
    z-index: 99;
    flex-direction: column;
  }

  .with-reply-time {
    padding-bottom: 0;
  }
}
