.social {
  &__links {
    display: flex;
    align-items: center;
    gap: var(--sbt-spacing-md);
    margin-top: var(--sbt-spacing-xs);
  }

  a {
    margin: 0;

    &:hover {
      color: var(--sbt-primary-color);
    }
  }
}
