@use '~@sbt-web/ui/common/css/variables';

.body {
  max-width: 400px;
}
.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: var(--sbt-spacing-sm);
}

.marginBottom {
  margin-bottom: var(--sbt-spacing-md);
}
