@use '~@sbt-web/ui/common/css/variables.scss' as v;

.name {
  &,
  a {
    color: var(--sbt-primary-text-color);
    &:hover {
      color: var(--sbt-secondary-text-color);
      text-decoration: underline;
    }
  }
  a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}
