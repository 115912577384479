@use '~@sbt-web/ui/common/css/variables';

.private-bottom-box {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-md);
  border: 1px solid variables.$neutralGrey4;
  border-radius: var(--sbt-corner-md);
  padding: var(--sbt-spacing-lg);
}

@media (min-width: variables.$tablet_min_width) {
  .private-bottom-box {
    flex-direction: row;
    justify-content: space-between;
  }
}

.contactButtonAndSocialization {
  display: flex;
  flex-direction: column;
  gap: var(--sbt-spacing-xs);

  > button > span {
    width: 100%;
    min-width: 256px;
  }
}
