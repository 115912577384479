@use '~@sbt-web/ui/common/css/variables.scss' as v;

.reply-time-text {
  margin: var(--sbt-spacing-sm) 0 var(--sbt-spacing-3xs) 0;
  text-align: center;
  @media (min-width: v.$tablet_min_width) {
    color: var(--sbt-secondary-text-color);
    margin: 0;
    align-content: center;
  }
}
